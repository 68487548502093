import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store, createSelector } from "@ngxs/store";
import { patch, updateItem } from "@ngxs/store/operators";
import moment from "moment";
import { tap } from "rxjs/operators";
import { Permission } from "src/app/core/enums/permission.enum";
import { OrdersStatsSummary } from "src/app/core/interfaces/dispatch-stats.interface";
import { Worker } from "src/app/core/interfaces/worker.interface";
import { SetError } from "src/app/features/admin/admin.state";
import { AvailabilityService } from "src/app/features/availability/services/availability.service";
import { DispatchService } from "src/app/features/dispatch/services/dispatch.service";
import { RosterService } from "src/app/features/roster/services/roster.service";
import { UsersState } from "src/app/features/users/state/users.state";
import { WorkerAvailability } from "src/app/shared/interfaces/worker-availability.interface";
import { getUpdatedItems } from "src/app/shared/utils/utils";
import { LaborOrder } from "../../../core/interfaces/labor-order.interface";
import { DispatchOrderState } from "../../../shared/enums/dispatch-order-state.enum";
import { OrdersService } from "../../orders/services/orders.service";
import { WorkerUnions } from "../../roster/interfaces/worker-union.interface";
import { DispatchByDateService } from "../services/dispatch-by-date.service";
import {
	AssignJobRequest,
	DeleteJobAssignment,
	LoadDispatchByDateStats,
	LoadSumarizedJobDetailInfo,
	LoadSumarizedOrders,
	LoadWorkersDBD,
	ReloadWorkersDBD,
	RemoveOrderById,
	ResetSumarizeOrders,
	SaveAvailabilityWorkerForToday,
	SaveAvailabilityWorkerWithExpireDate,
	SaveNoteForWorker,
	SetIsAssigned,
	SetRegularWorker,
	SetTOWorker,
	SetTTWorker,
	SetWillCallWorker,
	UpdateJobStatus,
	UpdateOrderAfterRecall,
	UpdateOrderAfterSetback,
	UpdateWhenWorkAndCalls,
	UpdateWorkerEnhancedNotes,
	UpdateWorkerFilters,
	UpdateWorkerOnRoster,
	UpdateWorkerReplacementEnhancedNotes,
	WorkerAssignmentOrder
} from "./dispatch-by-date.actions";
import { DispatchByDateStateModel, WorkerFiltersDBD } from "./dispatch-by-date.model";

@State<DispatchByDateStateModel>({
	name: "dispatchByDate",
	defaults: {
		loading: false,
		saving: false, 

		sumarizeOrders: null,
		dispatchByDateStats: null,

		searchDate: '',
		searchUnionId: null,
		allowBump: false,
		useWorkerDayCallResult: false,

    workersLoading: false,
		workersFilters: {
			SearchQ: "",
			AvailabilityTypeIds: [],
			jobTypes: [],
			queueType: null,
			onlyBumpeds: false,
			willCall: false,
			regular: false,
			skipCount: 0,
			maxCount: 30
		},
    allWorkers: null,
    totalWorkers: 0
	}
})
@Injectable()
export class DispatchByDateState {
	constructor(
		private store: Store,
		private dispatchService: DispatchService,
    private rosterService: RosterService,
		private orderService: OrdersService,
    private availabilityService: AvailabilityService,
		private dispatchByDateService: DispatchByDateService
	) {}

	@Selector() static loading(state: DispatchByDateStateModel) { return state.loading; }
	@Selector() static saving(state: DispatchByDateStateModel) { return state.saving; }
	@Selector() static sumarizeOrders(state: DispatchByDateStateModel) { return state.sumarizeOrders; }
	@Selector() static dispatchByDateStats(state: DispatchByDateStateModel) { return state.dispatchByDateStats; }
	@Selector() static searchedUnionId(state: DispatchByDateStateModel) { return state.searchUnionId; }
	@Selector() static allowBump(state: DispatchByDateStateModel) { return state.allowBump; }
	@Selector() static useWorkerDayCallResult(state: DispatchByDateStateModel) { return state.useWorkerDayCallResult; }
	@Selector() static workerFilters(state: DispatchByDateStateModel) { return state.workersFilters; }
	@Selector() static searchedDate(state: DispatchByDateStateModel) { return state.searchDate; }
  @Selector() static allWorkers(state: DispatchByDateStateModel) { return state.allWorkers; }
  @Selector() static workersLoading(state: DispatchByDateStateModel) { return state.workersLoading; }
	@Selector() static totalWorkers(state: DispatchByDateStateModel) { return state.totalWorkers; }
  static workerById(workerId: number) {
    return createSelector([DispatchByDateState], (state: DispatchByDateStateModel) => {
      return state.allWorkers.find((w) => { return w.id === workerId});
    });
  }

	@Action(LoadSumarizedOrders)
	loadSumarizedOrders(ctx: StateContext<DispatchByDateStateModel>, action: LoadSumarizedOrders) {
		const workerFilters: WorkerFiltersDBD = ctx.getState().workersFilters;
		ctx.patchState({
			loading: true,
			sumarizeOrders: null,
      dispatchByDateStats: null,
			searchDate: action.date,
			searchUnionId: action.unionId,
			allowBump: action.allowBump,
			useWorkerDayCallResult: action.useWorkerDayCallResult,
			workersFilters: {
				...workerFilters,
				onlyBumpeds: action.allowBump ? workerFilters.onlyBumpeds : false
			}
		});

		return this.orderService.getSumarizeListLightInfo(action.date, action.unionId).pipe(
			tap((response: any) => {
				const sumarizeOrders: LaborOrder[] = response.laborOrderSumary?.map((e) => {
					let order: LaborOrder = { ...e };
					order.isLoaded = false;
					return order;
				});
				ctx.patchState({
					sumarizeOrders: sumarizeOrders,
					dispatchByDateStats: response.laborOrderSumaryHeader,
					loading: false
				});
			})
		);
	}

	@Action(RemoveOrderById)
	removeOrderById(ctx: StateContext<DispatchByDateStateModel>, action: RemoveOrderById) {
		const state = ctx.getState();
		const sumarizeOrders = state.sumarizeOrders.filter((order: any) => order.id != action.id);

		ctx.patchState({
			sumarizeOrders: sumarizeOrders
		});

		ctx.dispatch(new LoadDispatchByDateStats());
	}	

	@Action(AssignJobRequest)
	assignJobRequest(ctx: StateContext<DispatchByDateStateModel>, { workers, callback }) {
		return this.dispatchService.assignJobRequest( workers ).pipe(
			tap(
				(response) => {
					callback({ assignmentStatus: response });
					ctx.dispatch([
						new LoadDispatchByDateStats(),
						new ReloadWorkersDBD()
					]);
				},
				error => {
					callback(error);
				}
			)
		);
	}

	@Action(WorkerAssignmentOrder)
	workerAssignmentOrder(ctx: StateContext<DispatchByDateStateModel>, { workers, callback}) {
		return this.dispatchService.workerAssignmentOrder(workers).pipe(
			tap(
				(res: any) => {
					callback({ assignmentStatus: res });
					ctx.dispatch(new LoadDispatchByDateStats());
				},
				error => {
					callback(error);
				}
			)
		);
	}

	@Action(DeleteJobAssignment)
	deleteJobAssignment(ctx: StateContext<DispatchByDateStateModel>, { jobAssignmentId, callbackError, callbackSuccess }) {
		return this.dispatchService.deleteJobAssignment(jobAssignmentId).pipe(
			tap(
				(response) => {
					callbackSuccess(response);
					ctx.dispatch(new LoadDispatchByDateStats());
					const allowBump: boolean = ctx.getState().allowBump;
					if (allowBump) {
						ctx.dispatch(new ReloadWorkersDBD());
					}
				},
				(error) => {
					callbackError(error);
				}
			)
		);
	}

	@Action(ResetSumarizeOrders)
	resetSumarizeOrders(ctx: StateContext<DispatchByDateStateModel>, { }) {
		ctx.patchState({ 
			sumarizeOrders: [],
			allWorkers: null,
			allowBump: false,
			useWorkerDayCallResult: false
		});
	}

	@Action(LoadSumarizedJobDetailInfo)
	loadSumarizedJobDetailInfo(ctx: StateContext<DispatchByDateStateModel>, action: LoadSumarizedJobDetailInfo) {
		ctx.patchState({ loading: true });
		return this.orderService.getSumarizeJobDetailList(action.laborOrderId, action.unionId, action.date).pipe(
			tap(
				(response: any) => {
					const state = ctx.getState();
					let foundOrder = JSON.parse(
						JSON.stringify(state.sumarizeOrders.find(o => o.id == action.laborOrderId))
					);
					let jobsAssignedCount = 0;
					response.forEach((r) => {
						r.jobList.forEach((jl) => {
							jobsAssignedCount += jl.jobAssignments.length;

							jl.jobAssignments?.forEach((ja) => {
								ja.isBumped = ja.replacements?.length ? ja.replacements[0].isBumped : false,
								ja.creationTime = new Date(ja.creationTime + '+0000');
								ja.hasBeenReplaced = ja.replacements.length > 0;
								ja.replacements?.forEach((replace, index) => {
									replace.creationTime = new Date(replace.creationTime + '+0000');
									replace.hasBeenReplaced = index == ja.replacements.length - 1 ? false : true;
									replace.isBumped = ja.replacements[index + 1] ? ja.replacements[index + 1].isBumped : false
								});
							});
						});
					});
					foundOrder.jobDetails = response;
					foundOrder.jobsAssignedCount = jobsAssignedCount;
					foundOrder.isLoaded = true;
					foundOrder.jobDetails?.forEach(jd => {
						jd.laborOrderId = action.laborOrderId;
					});
					ctx.patchState({
						sumarizeOrders: getUpdatedItems(foundOrder, state.sumarizeOrders),
						loading: false
					});
				}
			)
		);
	}

	@Action(UpdateWorkerEnhancedNotes)
	updateWorkerEnhancedNotes(ctx: StateContext<DispatchByDateStateModel>, { orderId, jobDetailId, jobId, jobAssignmentId, note }) {
		ctx.patchState({ loading: true });

		const state = ctx.getState();
		let foundOrder = JSON.parse(JSON.stringify(state.sumarizeOrders.find(o => o.id == orderId)));

		foundOrder.jobDetails
			.find(x => x.id === jobDetailId)
			.jobList.find(x => x.id === jobId)
			.jobAssignments.find(x => x.id === jobAssignmentId).worker.workerUnions[0].enhancedNotes = note;

		ctx.patchState({
			sumarizeOrders: getUpdatedItems(foundOrder, state.sumarizeOrders),
			loading: false
		});
	}

	@Action(UpdateWorkerReplacementEnhancedNotes)
	updateWorkerReplacementEnhancedNotes(ctx: StateContext<DispatchByDateStateModel>, { orderId, jobDetailId, jobId, jobAssignmentId, replacementId, note }) {
		ctx.patchState({ loading: true });

		const state = ctx.getState();

		let foundOrder = JSON.parse(JSON.stringify(state.sumarizeOrders.find(o => o.id == orderId)));

		foundOrder.jobDetails
			.find(x => x.id === jobDetailId)
			.jobList.find(x => x.id === jobId)
			.jobAssignments.find(x => x.id === jobAssignmentId)
			.replacements.find(x => x.id === replacementId)
			.worker.workerUnions[0].enhancedNotes = note;

		ctx.patchState({
			sumarizeOrders: getUpdatedItems(foundOrder, state.sumarizeOrders),
			loading: false
		});
	}

	@Action(UpdateOrderAfterSetback)
	updateOrderAfterSetback(ctx: StateContext<DispatchByDateStateModel>, { order }) {
		const state = ctx.getState();
		const updatedOrders: Array<LaborOrder> = JSON.parse(JSON.stringify(state.sumarizeOrders)).map((o) => {
			if (o.id === order.id) {
				o.startDate = order.startDate;
				o.startTime = order.startTime;
				o.multipleStartTime = order.multipleStartTime;
				o.jobDetails = o.jobDetails.map((j) => {
					j.dispatchState = DispatchOrderState.Open;
					const responseJobIndex: number = order.jobDetails.findIndex(
						(job) => { return job.id === j.id; }
					);

					j.startDate = order.jobDetails[responseJobIndex].startDate;
					j.startTime = order.jobDetails[responseJobIndex].startTime;
					return j;
				});
			}
			return o;
		});

		ctx.patchState({
			sumarizeOrders: updatedOrders
		});
	}

	@Action(UpdateOrderAfterRecall)
	updateOrderAfterRecall(ctx: StateContext<DispatchByDateStateModel>, action: UpdateOrderAfterRecall) {
		const state = ctx.getState();
		const updatedOrders: Array<LaborOrder> = JSON.parse(JSON.stringify(state.sumarizeOrders)).map((o) => {
			if (o.id === action.order.id) {
				o.jobDetails = o.jobDetails.map((j) => {
					j.dispatchState = DispatchOrderState.Open;
					return j;
				});
			}
			return o;
		});

		ctx.patchState({
			sumarizeOrders: updatedOrders
		});
	}

	@Action(LoadDispatchByDateStats)
	loadDispatchByDateStats(ctx: StateContext<DispatchByDateStateModel>) {
		const state = ctx.getState();
		if (state.searchDate && state.searchUnionId) {
			return this.dispatchByDateService.loadDispatchByDateStats(state.searchDate, state.searchUnionId).pipe(
				tap(
					(response: OrdersStatsSummary) => {
						ctx.patchState({
							dispatchByDateStats: response
						})
					}
				)
			)
		}
	}

  @Action(UpdateJobStatus)
	updateJobStatus(ctx: StateContext<DispatchByDateStateModel>, { orderId, jobId, newState }) {
		ctx.patchState({ loading: true });
		
		const state = ctx.getState();
		let order = JSON.parse(JSON.stringify(state.sumarizeOrders.find(x => x.id == orderId)));
		order.jobDetails.find(x => x.id === jobId).dispatchState = newState;

		ctx.patchState({
			sumarizeOrders: getUpdatedItems(order, state.sumarizeOrders),
			loading: false
		});
	}

  @Action(LoadWorkersDBD)
	loadWorkers(ctx: StateContext<DispatchByDateStateModel>, action: LoadWorkersDBD) {
		const state = ctx.getState();

		if (!state.searchUnionId && !state.searchDate) {
			return;
		}

		if (action.addWorkers) {
			ctx.patchState({
				workersLoading: true
			});
		} else {
			ctx.patchState({
				allWorkers: null,
				workersLoading: true
			});
		}

		return this.rosterService.getWorker(
			state.workersFilters.SearchQ,
			state.searchUnionId,
			action.skipCount,
			30,
			moment(state.searchDate).format('YYYY-MM-DD'),
			state.workersFilters.AvailabilityTypeIds,
			state.workersFilters.jobTypes,
			true,
			true,
			null,
			state.workersFilters.queueType,
			state.workersFilters.onlyBumpeds,
			state.workersFilters.willCall,
			state.workersFilters.regular
		).pipe(
			tap(
        (response) => {
					const newWorkers: Worker[] = response.items.map((w) => {
						if (w.queueTime) {
							w.queueTime = new Date(w.queueTime + '+0000');
						}

						const isWQ: boolean = ['W', 'Q'].includes(w.currentDayAvailability.availability.code);
						const allowBump: boolean = state.allowBump;
						const userRole: Permission = this.store.selectSnapshot(UsersState.auth).userRole[0];
						const isUnionDispatcherOrAdmin: boolean = [Permission.UnionDispatcher, Permission.Administrator].includes(userRole);
						w.isAssignable = !(isWQ && allowBump && !isUnionDispatcherOrAdmin);

						return w;
					});
					
					let allWorkers = [];
					if (action.addWorkers) {
						const state = ctx.getState();
						allWorkers = state.allWorkers.concat(newWorkers);
					} else {
						allWorkers = newWorkers;
					}
          
          ctx.patchState({
            allWorkers: allWorkers,
            loading: false,
            workersLoading: false,
            totalWorkers: response.totalCount
          });
        }
      )
		);
	}

	@Action(ReloadWorkersDBD)
	reloadWorkers(ctx: StateContext<DispatchByDateStateModel>) {
		const state = ctx.getState();

		return this.rosterService.getWorker(
			state.workersFilters.SearchQ,
			state.searchUnionId,
			state.workersFilters.skipCount,
			state.allWorkers.length,
			moment(state.searchDate).format('YYYY-MM-DD'),
			state.workersFilters.AvailabilityTypeIds,
			state.workersFilters.jobTypes,
			true,
			true,
			null,
			state.workersFilters.queueType,
			state.workersFilters.onlyBumpeds,
			state.workersFilters.willCall,
			state.workersFilters.regular,
		).pipe(
			tap(
        (response) => {
					const newWorkers: Worker[] = response.items.map((w) => {
						if (w.queueTime) {
							w.queueTime = new Date(w.queueTime + '+0000');
						}

						const isWQ: boolean = ['W', 'Q'].includes(w.currentDayAvailability.availability.code);
						const allowBump: boolean = state.allowBump;
						const userRole: Permission = this.store.selectSnapshot(UsersState.auth).userRole[0];
						const isUnionDispatcherOrAdmin: boolean = [Permission.UnionDispatcher, Permission.Administrator].includes(userRole);
						w.isAssignable = !(isWQ && allowBump && !isUnionDispatcherOrAdmin);
						
						return w;
					});
          
          ctx.patchState({
            allWorkers: newWorkers,
            loading: false,
            workersLoading: false,
            totalWorkers: response.totalCount
          });
        }
      )
		);
	}

	@Action(UpdateWorkerFilters)
	updateWorkerFilters(ctx: StateContext<DispatchByDateStateModel>, action: UpdateWorkerFilters) {
		const state = ctx.getState();
		ctx.patchState({
			workersFilters: {
				...state.workersFilters,
				...action.filters
			}
		});

		if (action.search) {
			ctx.dispatch(
				new LoadWorkersDBD(0, false)
			);
		}
	}

  @Action(SaveAvailabilityWorkerForToday)
	saveAvailabilityWorkerForToday(ctx: StateContext<DispatchByDateStateModel>, { availability, dateFrom, callback }) {
		ctx.patchState({ loading: true });

		return this.availabilityService.saveAvailability(availability).pipe(
			tap(
				(response: WorkerAvailability[]) => {
					const state = ctx.getState();
					let foundWorker = JSON.parse(
						JSON.stringify(state.allWorkers.find(w => w.id == response[0].workerId))
					);
					
					const findAvailability: WorkerAvailability = response.find(
						(o) => { 
							return o.date.split('T')[0] == dateFrom.split('T')[0]
						} 
					);

					if (findAvailability) {
						foundWorker.currentDayAvailability = findAvailability;
					} 

					ctx.patchState({
						allWorkers: getUpdatedItems(foundWorker, state.allWorkers),
						loading: false
					});
					callback();
				},
				error => {
					callback(error);
					ctx.patchState({ loading: false });
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

	@Action(SaveAvailabilityWorkerWithExpireDate)
	saveAvailabilityWorkerWithExpireDate(ctx: StateContext<DispatchByDateStateModel>, { availability, dateFrom, callback }) {
		ctx.patchState({ loading: true });

		return this.availabilityService.saveAvailabilityWithExpireDate(availability).pipe(
			tap(
				(response: WorkerAvailability[]) => {
					const state = ctx.getState();
					let foundWorker = JSON.parse(
						JSON.stringify(state.allWorkers.find(w => w.id == response[0].workerId))
					);

					const findAvailability: WorkerAvailability = response.find(
						(o) => { 
							return o.date.split('T')[0] == dateFrom.split('T')[0]
						} 
					);

					if (findAvailability) {
						foundWorker.currentDayAvailability = findAvailability;
					}
					
					ctx.patchState({
						allWorkers: getUpdatedItems(foundWorker, state.allWorkers),
						loading: false
					});
					callback();
				},
				error => {
					callback(error);
					ctx.patchState({ loading: false });
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

  @Action(SaveNoteForWorker)
	saveNoteForWorker(ctx: StateContext<DispatchByDateStateModel>, { workerId, value, callback }) {
		ctx.patchState({ loading: true });
		return this.rosterService.saveNote(workerId, value).pipe(
			tap(
				(response) => {
					const state = ctx.getState();
					let foundWorker: Worker = JSON.parse(
						JSON.stringify(state.allWorkers.find(w => w.id == workerId))
					);

					const updatedDates: string[] = value.map((e) => { return e.date; });
					const filteredDate: string = moment(state.searchDate).format('YYYY-MM-DD');

					if (updatedDates.includes(filteredDate)) {
						foundWorker.workerUnions.forEach(element => {
							if (element.unionId == value[0].unionId) {
								element.enhancedNotes = value[0].enhancedNotes;
							}
						});
					}

					ctx.patchState({
						allWorkers: getUpdatedItems(foundWorker, state.allWorkers),
						loading: false
					});
					callback();
				},
				(error) => {
					callback(error);
					ctx.patchState({ loading: false });
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

  @Action(SetIsAssigned)
	setIsAssigned(ctx: StateContext<DispatchByDateStateModel>, { workerId, assignmentStatus }) {
		ctx.patchState({ loading: true });

		const state = ctx.getState();
		const foundWorker: Worker = state.allWorkers.find(w => w.id == workerId);
		
		if (foundWorker) {
			let workerCopy = JSON.parse(JSON.stringify(foundWorker));
			workerCopy.assignmentStatus = assignmentStatus;
	
			ctx.patchState({
				allWorkers: getUpdatedItems(workerCopy, state.allWorkers),
				loading: false
			});
		}
	}

	@Action(UpdateWorkerOnRoster)
	updateWorkerOnRoster(ctx: StateContext<DispatchByDateStateModel>, { worker }) {
    const state = ctx.getState();

    const foundWorker = state.allWorkers?.find(item => item.id == worker.id);
    if (foundWorker && state.searchDate.split("T")[0] === worker.updateInfo.dateOfUpdate.split("T")[0]) {
      let originalFoundWorker = JSON.stringify(foundWorker);
      let newFoundWorker = JSON.parse(originalFoundWorker);
	    let foundOrdersForNotes = new Array<LaborOrder>();
	
      if (worker.assignmentStatus != null) { newFoundWorker.assignmentStatus = worker.assignmentStatus; }
      if (worker.currentDayAvailability != null) { newFoundWorker.currentDayAvailability = worker.currentDayAvailability; }
      if (worker.workerUnions != null && worker.workerUnions[0].enhancedNotes != null) {
        newFoundWorker.workerUnions.forEach(element => {
          if (element.unionId == worker.updateInfo.unionId) {
            element.enhancedNotes = worker.workerUnions[0].enhancedNotes;
          }
        });

        state.sumarizeOrders.forEach(order => {
          if (order.jobDetails != null) {
            order.jobDetails.forEach(jobDetail => {
              jobDetail.jobList.forEach(jobList => {
                jobList.jobAssignments.forEach(jobAssignment => {
                  if (jobAssignment.workerId == worker.id) {
                    foundOrdersForNotes.push(order);
									}
									jobAssignment.replacements?.forEach((r) => {
										if (r.worker.id === worker.id) {
											foundOrdersForNotes.push(order);
										}
									});
                });
              });
            });
          }
        });
      }

      ctx.patchState({
        allWorkers: getUpdatedItems(newFoundWorker, state.allWorkers),
        loading: false
      });

      const foundOrder = state.sumarizeOrders.find(item => item.id == worker.updateInfo.laborOrderId);
      if (foundOrder) {
        ctx.dispatch(
          new LoadSumarizedJobDetailInfo(
          worker.updateInfo.laborOrderId,
          worker.updateInfo.unionId,
          worker.updateInfo.dateOfUpdate
          )
        );
      } else {
        if (foundOrdersForNotes.length > 0) {
          foundOrdersForNotes.forEach(o => {
            ctx.dispatch(
              new LoadSumarizedJobDetailInfo(
                o.id,
                worker.updateInfo.unionId || state.searchUnionId,
                worker.updateInfo.dateOfUpdate
              )
            );
          });
        }       
      }
    }
  }

	@Action(UpdateWhenWorkAndCalls, { cancelUncompleted: true })
	updateWhenWorkAndCalls(ctx: StateContext<DispatchByDateStateModel>, action: UpdateWhenWorkAndCalls) {
		ctx.patchState({
			saving: true
		});

		return this.dispatchByDateService.updateWhenWorkAndCalls(
			action.workerUnionId,
			action.preferredDayTime,
			action.jobPreference,
			action.noAnswerCount,
			action.declinedCount
		).pipe(
			tap(
				(response) => {
					ctx.setState(
						patch({
							saving: false,
							allWorkers: updateItem<Worker>(
								item => { return item.id === action.workerId; }, 
								patch({
									workerUnions: updateItem<WorkerUnions>(
										union => { return union.id === action.workerUnionId; },
										patch({
											preferredDayTime: action.preferredDayTime,
											jobPreference: action.jobPreference,
											noAnswerCount: action.noAnswerCount,
											declinedCount: action.declinedCount
										})
									)
								})
							)	
						})
					)
				},
				(error) => {
					ctx.patchState({ saving: false });
				}
			)
		)
	}	

	@Action(SetTTWorker)
	setTTWorker(ctx: StateContext<DispatchByDateStateModel>, action: SetTTWorker) {
		ctx.patchState({ loading: true });

		return this.dispatchByDateService.setTTWorker(action.jobAssignmentId, action.ttValue).pipe(
			tap(
				(response) => {
					action.callbackSuccess(response);
				},
				(error) => {
					action.callbackError(error);
					ctx.patchState({ loading: false });
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

	@Action(SetTOWorker)
	setTOWorker(ctx: StateContext<DispatchByDateStateModel>, action: SetTOWorker) {
		ctx.patchState({ loading: true });

		return this.dispatchByDateService.setTOWorker(action.jobAssignmentId, action.toValue).pipe(
			tap(
				(response) => {
					action.callbackSuccess(response)
				},
				error => {
					action.callbackError(error);
					ctx.patchState({ loading: false });
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

	@Action(SetWillCallWorker)
	setWillCallWorker(ctx: StateContext<DispatchByDateStateModel>, action: SetWillCallWorker) {
		ctx.patchState({ loading: true });

		return this.dispatchByDateService.setWillCallWorker(action.workerId, action.unionId, action.value).pipe(
			tap(
				(response) => {
					ctx.setState(
						patch({
							allWorkers: updateItem<Worker>(
								item => { return item.id === action.workerId; }, 
								patch({
									workerUnions: updateItem<WorkerUnions>(
										union => { return union.unionId === action.unionId; },
										patch({
											willCall: action.value,
										})
									)
								})
							)	
						})
					)
					action.callbackSuccess(response)
				},
				error => {
					action.callbackError(error);
					ctx.patchState({ loading: false });
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

	@Action(SetRegularWorker)
	setRegularWorker(ctx: StateContext<DispatchByDateStateModel>, action: SetRegularWorker) {
		ctx.patchState({ loading: true });

		return this.dispatchByDateService.setRegularWorker(action.workerId, action.unionId, action.value).pipe(
			tap(
				(response) => {
					ctx.setState(
						patch({
							allWorkers: updateItem<Worker>(
								item => { return item.id === action.workerId; }, 
								patch({
									workerUnions: updateItem<WorkerUnions>(
										union => { return union.unionId === action.unionId; },
										patch({
											reg: action.value,
										})
									)
								})
							)	
						})
					)
					action.callbackSuccess(response)
				},
				error => {
					action.callbackError(error);
					ctx.patchState({ loading: false });
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

}
