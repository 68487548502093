import { WorkerAvailability } from 'src/app/shared/interfaces/worker-availability.interface';
import { AssignWorker as AssignWorkerInterface } from '../../../core/interfaces/assign-worker.interfaces';
import { LaborOrder } from "../../../core/interfaces/labor-order.interface";
import { DispatchOrderState } from '../../../shared/enums/dispatch-order-state.enum';
import { OrderWorker } from '../interfaces/order-worker.interface';
import { WorkerFiltersDBD } from './dispatch-by-date.model';

export class LoadSumarizedOrders {
	static readonly type = "[DBD] Load Sumarized Orders";
	constructor(
		public date: string,
		public unionId: number,
		public allowBump: boolean,
		public useWorkerDayCallResult: boolean
	) {}
}

export class ResetSumarizeOrders {
	public static readonly type = "[DBD] reset sumariezed orders";
	constructor() {}
}

export class RemoveOrderById {
	static readonly type = "[DBD] Remove order by Id";
	constructor(
    public id: string
  ) {}
}

export class LoadSumarizedJobDetailInfo {
	static readonly type = "[DBD] Load Sumarized Orders Job Detail";
	constructor(
		public laborOrderId: string,
		public unionId: number,
		public date: string) {}
}

export class AssignJobRequest {
	static readonly type = "[DBD] AssignJobRequest";
	constructor(
		public workers: AssignWorkerInterface[],
		public callback: (error) => void
	) {}
}

export class DeleteJobAssignment {
	static readonly type = "[DBD] delete job assignment";
	constructor(
		public jobAssignmentId: string,
		public callbackError: (error) => void,
		public callbackSuccess: (response) => void
	) {}
}

export class UpdateWorkerEnhancedNotes {
	static readonly type = "[DBD] update worker enhanced notes";
	constructor(
		public orderId: string,
		public jobDetailId: string,
		public jobId: string,
		public jobAssignmentId: string,
		public note: string
	) {}
}

export class UpdateWorkerReplacementEnhancedNotes {
	static readonly type = "[DBD] update worker replacement enhanced notes";
	constructor(
		public orderId: string,
		public jobDetailId: string,
		public jobId: string,
		public jobAssignmentId: string,
		public replacementId: string,
		public note: string
	) {}
}

export class WorkerAssignmentOrder {
	static readonly type = "[DBD] WorkerAssignmentOrder";
	constructor(
		public workers: OrderWorker[],
		public callback: (error) => void
	) {}
}

export class UpdateOrderAfterSetback {
	public static readonly type = "[DBD] update order after setback";
	constructor(
		public order: LaborOrder
	) {}
}

export class UpdateOrderAfterRecall {
	public static readonly type = "[DBD] update order after recall";
	constructor(
		public order: LaborOrder,
		public unionId: number
	) {}
}

export class UpdateJobStatus {
	static readonly type = "[Dispatch] update job status";
	constructor(
		public orderId: string,
		public jobId: string,
		public newState: DispatchOrderState
	) { }
}

export class LoadDispatchByDateStats {
	public static readonly type = "[DBD] load dispatch by date stats";
	constructor() {}
}

export class LoadWorkersDBD {
	static readonly type = "[DBD] Load workers";
	constructor(
		public skipCount: number,
		public addWorkers: boolean
	) {}
}

export class ReloadWorkersDBD {
	static readonly type = "[DBD] Reload workers";
	constructor() {}
}

export class UpdateWorkerFilters {
	static readonly type = "[DBD] Update workers filters";
	constructor(
		public filters: WorkerFiltersDBD,
		public search: boolean
	) {}
}

export class SaveAvailabilityWorkerForToday {
	public static readonly type = "[DBD] save availability for worker for today";
	constructor(
    public availability: WorkerAvailability,
    public dateFrom: string,
    public callback: (error) => void
  ) {}
}

export class SaveAvailabilityWorkerWithExpireDate {
	public static readonly type = "[DBD] save availability for worker whith expire date";
	constructor(
    public availability: WorkerAvailability,
    public dateFrom: string,
    public callback: (error) => void
  ) {}
}

export class SaveNoteForWorker {
	public static readonly type = "[DBD] save note for worker ";
	constructor(
		public workerId: number,
		public value: any[],
		public callback: (error) => void
	) { }
}

export class SetIsAssigned {
	static readonly type = "[DBD] set is assigned";
	constructor(
		public workerId: number,
		public assignmentStatus: number
	) { }
}

export class UpdateWorkerOnRoster {
	public static readonly type = "[DBD] update roster";
	constructor(
    public worker: any
  ) {}
}

export class UpdateWhenWorkAndCalls {
	public static readonly type = "[DBD] update when work and calls";
	constructor(
		public workerId: number,
		public workerUnionId: string,
		public preferredDayTime: string,
    public jobPreference: string,
    public noAnswerCount: number,
    public declinedCount: number
	) {}
}

export class SetTTWorker {
	public static readonly type = "[DBD] set TT worker";
	constructor(
		public jobAssignmentId: string,
		public ttValue: boolean,
		public callbackSuccess: (response) => void,
		public callbackError: (error) => void
	) {}
}

export class SetTOWorker {
	public static readonly type = "[DBD] set TO worker";
	constructor(
		public jobAssignmentId: string,
		public toValue: boolean,
		public callbackSuccess: (response) => void,
		public callbackError: (error) => void
	) {}
}

export class SetWillCallWorker {
	public static readonly type = "[DBD] set will call worker";
	constructor(
		public workerId: number,
		public unionId: number,
		public value: boolean,
		public callbackSuccess: (response) => void,
		public callbackError: (error) => void
	) {}
}

export class SetRegularWorker {
	public static readonly type = "[DBD] set regular worker";
	constructor(
		public workerId: number,
		public unionId: number,
		public value: boolean,
		public callbackSuccess: (response) => void,
		public callbackError: (error) => void
	) {}
}