import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { API_URL } from 'src/environments/settings';
import { OrdersStatsSummary } from '../../../core/interfaces/dispatch-stats.interface';

@Injectable({ providedIn: 'root' })
export class DispatchByDateService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  loadDispatchByDateStats(date: string, unionId: number) {
		return this.http.get<OrdersStatsSummary>(`${this.apiUrl}/app/laborOrder/sumarizeHeader?Date=${date}&UnionId=${unionId}`);
	}
  
  updateWhenWorkAndCalls(
    workerUnionId: string,
    preferredDayTime: string,
    jobPreference: string,
    noAnswerCount: number,
    declinedCount: number
  ) {
    return this.http.put(`${this.apiUrl}/app/worker/${workerUnionId}/workerDayCallResult`, {
      preferredDayTime,
      jobPreference,
      noAnswerCount,
      declinedCount
    });
  }

  setTTWorker(jobAssignmentId: string, ttValue: boolean) {
    return this.http.post(`${this.apiUrl}/app/dispatchOrder/${jobAssignmentId}/setTtWorker?ttValue=${ttValue}`, {})
  }

  setTOWorker(jobAssignmentId: string, toValue: boolean) {
    return this.http.post(`${this.apiUrl}/app/dispatchOrder/${jobAssignmentId}/setToWorker?toValue=${toValue}`, {})
  }

  setWillCallWorker(workerId: number, unionId: number, willCallValue: boolean) {
    return this.http.post(`${this.apiUrl}/app/worker/setWillCall?workerId=${workerId}&unionId=${unionId}&value=${willCallValue}`, {})
  }

  setRegularWorker(workerId: number, unionId: number, regValue: boolean) {
    return this.http.post(`${this.apiUrl}/app/worker/setReg?workerId=${workerId}&unionId=${unionId}&value=${regValue}`, {})
  }

}