import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import moment from "moment";
import { tap } from "rxjs/operators";
import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { Worker } from "../../../../core/interfaces/worker.interface";
import { SenioritiesFilters } from "../interfaces/seniorities-filters.interface";
import { SenioritiesService } from "../services/seniorities.service";
import { LoadSeniorityClass, LoadWorkersByUnion, UpdateWorkerSeniorityNumber, UpdateWorkersFilters } from "./seniorities.actions";

export class SenioritiesStateModel {
  loading: boolean;
  saving: boolean;
  error: boolean;
  
  workers: Array<Worker>;
  totalCount: number;
  filters: SenioritiesFilters;
  seniorityClass: SelectOption[];
}

@Injectable()
@State<SenioritiesStateModel>({
  name: "seniorities",
  defaults: {
    loading: false,
    saving: false,
    error: false,

    workers: [],
    totalCount: 0,
    filters: null,
    seniorityClass: []
  }
})
export class SenioritiesState {
  constructor(
    private senioritiesService: SenioritiesService
  ) {}

  @Selector() static workers(state: SenioritiesStateModel) { return state.workers; }
  @Selector() static totalWorkersCount(state: SenioritiesStateModel) { return state.totalCount; }
  @Selector() static seniorityClass(state: SenioritiesStateModel) { return state.seniorityClass; }
  @Selector() static filters(state: SenioritiesStateModel) { return state.filters; }
  @Selector() static loading(state: SenioritiesStateModel) { return state.loading; }
  @Selector() static saving(state: SenioritiesStateModel) { return state.saving; }
  @Selector() static error(state: SenioritiesStateModel) { return state.error; }

  @Action(LoadWorkersByUnion)
	loadWorkersByUnion(ctx: StateContext<SenioritiesStateModel>, action: LoadWorkersByUnion) {
		ctx.patchState({
      workers: [],
      loading: true
    });

    const filters: SenioritiesFilters = ctx.getState().filters;
    if (filters?.UnionId && filters?.date) {
      return this.senioritiesService.getWorkersByUnion(
        filters?.UnionId,
        action.skipCount,
        action.maxResultsCount,
        filters?.searchQ,
        filters?.class || '',
        moment(filters?.date).format('YYYY-MM-DD')
      ).pipe(
        tap(
          (response) => {
            ctx.patchState({
              workers: response.items,
              totalCount: response.totalCount,
              loading: false
            });
          },
          (error) => {
            ctx.patchState({
              workers: [],
              totalCount: 0,
              loading: false,
              error: true
            });
          }
        )
      ).subscribe();
    } else {
      ctx.patchState({
        workers: [],
        totalCount: 0,
        loading: false,
        error: false
      });
    }
	}

  @Action(UpdateWorkerSeniorityNumber)
  updateWorkerSeniorityNumber(ctx: StateContext<SenioritiesStateModel>, action: UpdateWorkerSeniorityNumber) {
    return this.senioritiesService.updateWorkerSeniority(action.id, action.seniorityNumber).then(
      (response) => { action.callbackSuccess(); },
      (error) => { action.callbackError(error); }
    )
  }

  @Action(LoadSeniorityClass)
  loadSeniorityClass(ctx: StateContext<SenioritiesStateModel>, action: LoadSeniorityClass) {
    if (action.unionId) {
      return this.senioritiesService.getSeniorityClass(action.unionId).pipe(
        tap(
          (response) => {
            ctx.patchState({
              seniorityClass: [
                { value: '', text: 'All Class' },
                ...response.map((e) => { return { value: e, text: e } })
              ]
            })
          }
        )
      );
    } else {
      ctx.patchState({
        seniorityClass: []
      });
    }
  }

  @Action(UpdateWorkersFilters)
	updateFilters(ctx: StateContext<SenioritiesStateModel>, action: UpdateWorkersFilters) {
		ctx.patchState({ filters: action.filters });
	}
}
